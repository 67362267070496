import { useToken } from '@chakra-ui/react'
import React from 'react'
import { useCounter, useInterval } from 'react-use'
import {
  Bar,
  BarChart,
  CartesianGrid,
  Customized,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts'

import { useChartTheme } from '../../../themes'
import { BackgroundGradientComponent } from '../../../time-series/TimeSeriesChart'

const values = [10, 7, 7, 6, 4, 8, 5, 4, 6, 9]
const dataset = values.map((v, i) => ({ date: new Date(i), value: v }))

const preAnimationDelay = 100
const animationDuration = 1600
const postAnimationDelay = 100

const totalAnimationDuration =
  preAnimationDelay + animationDuration + postAnimationDelay

export type BarChartSkeletonProps = {
  isLoaded?: boolean
  margin?: { top?: number; right?: number; bottom?: number; left?: number }
  children?: React.ReactNode
}
export const BarChartSkeleton: React.FC<BarChartSkeletonProps> = ({
  isLoaded,
  margin,
  children,
}) => {
  const theme = useChartTheme()
  const [gray300] = useToken('colors', ['gray.300'])

  /**
   * Recharts line graphs have a nice animation when rendering a line initially.
   * We repeat this initial animation by forcing a remount of the skeleton chart
   * by changing it `key` prop in intervals.
   * It's a bit of a hack but recharts won't repeat the animation otherwise.
   */
  const [forceUpdateKey, { inc }] = useCounter(0)
  useInterval(() => inc(1), isLoaded ? null : totalAnimationDuration)

  if (isLoaded) {
    return <>{children}</>
  }

  return (
    <ResponsiveContainer width="100%" height="100%" minWidth={100}>
      <BarChart data={dataset} key={forceUpdateKey} margin={margin}>
        <CartesianGrid
          horizontal={false}
          stroke={theme.gridColor}
          strokeDasharray={4}
        />
        <XAxis
          dataKey="date"
          tickFormatter={() => ''}
          stroke={theme.gridColor}
          tick={{ fill: theme.gridTextColor }}
        />
        <YAxis
          tickFormatter={() => ''}
          stroke={theme.gridColor}
          tick={{ fill: theme.gridTextColor }}
          width={50}
        />
        <Customized
          key="background-gradient"
          component={<BackgroundGradientComponent />}
        />
        <Bar
          dataKey="value"
          fill={gray300}
          isAnimationActive
          animationEasing="ease"
          animationBegin={preAnimationDelay}
          animationDuration={animationDuration}
        />
      </BarChart>
    </ResponsiveContainer>
  )
}
