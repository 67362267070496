import { atom } from 'jotai'
import qs, { Stringifiable } from 'query-string'

const QUERY_KEY = 'score'

const writeSearchParam = (key: string, value: Stringifiable): void => {
  const url = qs.stringifyUrl({
    url: window.location.href,
    query: {
      [key]: value,
    },
  })
  window.history.replaceState(null, '', url)
}

const scoreAtom = atom('')
export const selectedScoreAtom = atom(
  (get) => get(scoreAtom),
  (_get, set, scoreUid: string) => {
    writeSearchParam(QUERY_KEY, scoreUid)
    set(scoreAtom, scoreUid)
  },
)
selectedScoreAtom.onMount = (setAtom) => {
  const usp = new URLSearchParams(window.location.search)
  const initialScore = usp.get(QUERY_KEY) ?? ''
  setAtom(initialScore)
}
