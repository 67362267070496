import { Box, List, ListIcon, ListItem, Text, VStack } from '@chakra-ui/react'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/macro'
import React from 'react'

import { ScoreBucket } from './ScoreDistributionChart'

export const pctFormat: Intl.NumberFormatOptions = {
  style: 'percent',
  minimumFractionDigits: 1,
  maximumFractionDigits: 1,
}

type Payload = {
  value: number
  name: string
  payload: ScoreBucket
  dataKey: (d: ScoreBucket) => number
  color: string
}

type TooltipProps = {
  active?: boolean
  label?: number
  payload?: Payload[]
  formatValue?: (payload: Payload) => string
}

const ScoreDistributionTooltip: React.FC<TooltipProps> = ({
  active,
  label,
  payload,
  formatValue = (x) => i18n.number(x.value, pctFormat),
}) => {
  if (!(active && payload && payload.length)) {
    return null
  }
  return (
    <Box
      bg="white"
      border="1px"
      borderColor="gray.200"
      borderRadius="md"
      boxShadow="sm"
      p={4}
    >
      <VStack spacing={2} align="stretch">
        <Text fontWeight="medium">
          <Trans>Score: {label}</Trans>
        </Text>
        <List spacing={1} mt={1}>
          {payload.map((x, i) => {
            return (
              <ListItem key={i} display="flex" alignItems="center">
                <ListIcon
                  as={Box}
                  bg={x.color}
                  display="inline-block"
                  boxSize={2}
                  borderRadius="2px"
                />
                {formatValue(x)}
              </ListItem>
            )
          })}
        </List>
      </VStack>
    </Box>
  )
}

export default ScoreDistributionTooltip
