import {
  GetScoreTimeSeriesResponseModel,
  ScoreSummary,
  insightsAPI,
} from '@capturi/api-insights'
import { Score } from '@capturi/api-scoring'
import Icon_EmptyState from '@capturi/assets/images/EmptyState.svg'
import {
  CartesianGrid,
  DashedLineVariants,
  DateBucket,
  GoalReferenceArea,
  Line,
  LineChartSkeleton,
  LineDescriptor,
  PrimaryLine,
  ReferenceLine,
  ReferenceLineDescriptor,
  TimeSeriesChart,
  TimeSeriesTooltip,
  XAxis,
  YAxis,
  adjustDateForDSTOverlap,
} from '@capturi/charts'
import { TimeResolution, useCurrentUser, useTeams } from '@capturi/core'
import {
  Segment,
  useFetchMultiSegments,
  useFetchSegments,
  useFilterPeriodContext,
  useSegmentStatesContext,
} from '@capturi/filters'
import { DefaultFallbackComponent } from '@capturi/react-utils'
import { useUsers } from '@capturi/stores'
import { ContentPlaceholder, SectionHeading } from '@capturi/ui-components'
import {
  Box,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react'
import { i18n } from '@lingui/core'
import { Trans, t } from '@lingui/macro'
import { useStore } from '@tanstack/react-store'
import { isWeekend } from 'date-fns'
import uniq from 'lodash/uniq'
import React from 'react'
import { Customized, ResponsiveContainer, Tooltip } from 'recharts'

import { DATA_SOURCE } from '../../constants'
import { useScoreConversationsDrawer } from '../../drawers'
import { logEvent } from '../../events'
import { useOnRevalidateDataSource } from '../../hooks/useRevalidateDataSource'
import { useTabsState } from '../../hooks/useTabsState'
import {
  BreakdownViewtype,
  selectedBreakdownViewStore,
} from '../../state/selectedBreakdownView'
import { selectedTeamsStore } from '../../state/selectedTeamsState'
import { selectedUsersStore } from '../../state/selectedUsersState'

import ParameterHitsList from '../ParameterHitsList'
import { ScoreSummaryList } from '../ScoreSummaryList'
import TeamHitsList from '../TeamHitsList'
import UserHitsList from '../UserHitsList'

type Props = {
  score: Score
  resolution: TimeResolution
  onlyWeekDays: boolean
}

export const ScoreTimeSeriesView: React.FC<Props> = ({
  score,
  resolution,
  onlyWeekDays,
}) => {
  const currentUser = useCurrentUser()
  const { getUserByUid } = useUsers()
  const { getTeamByUid } = useTeams()
  const { period } = useFilterPeriodContext()
  const tzOffset = period.from.getTimezoneOffset() * -1
  const { periodDef } = useFilterPeriodContext()
  const { phoneSegmentStates: states } = useSegmentStatesContext()
  const openConversationsDrawer = useScoreConversationsDrawer()

  const plottableUsers = useStore(selectedUsersStore)
  const plottableTeams = useStore(selectedTeamsStore)

  const selectedBeakdownView = useStore(selectedBreakdownViewStore)

  const {
    segments: segmentSummaries,
    revalidate: revalidateSummaries,
    error: summaryError,
  } = useFetchSegments<ScoreSummary>(
    () => insightsAPI.getScoreSummary(score.uid),
    (state, _, periodDef, mapperFn) => {
      return mapperFn(
        {
          ...state.values,
          scores: [
            ...(state.values.scores ?? []),
            {
              uid: score.uid,
            },
          ],
        },
        periodDef,
      )
    },
  )

  const onViewConversations = (
    index: number,
    segment: Segment<ScoreSummary>,
  ): void => {
    openConversationsDrawer({
      score,
      periodDef,
      filterState: states[index].values,
      excludeDeletedConversations: false,
      segmentColorScheme: segment.color,
    })
    logEvent('view-conversations-drawer--clicked')
  }

  const { tabIndex, getTab, tabs } = useTabsState<{
    label: string
    value: BreakdownViewtype
    content: React.ReactElement
  }>(() => {
    return [
      {
        label: t`Criteria`,
        value: 'criteria',
        content: <ParameterHitsList score={score} />,
      },
      {
        label: t`Employees`,
        value: 'employees',
        content: (
          <UserHitsList
            score={score}
            selectableRows
            segmentSummaries={segmentSummaries}
          />
        ),
      },
      {
        label: t`Teams`,
        value: 'teams',
        content: (
          <TeamHitsList
            score={score}
            selectableRows
            segmentSummaries={segmentSummaries}
          />
        ),
      },
    ]
  }, selectedBeakdownView)

  const {
    segments,
    isLoading,
    revalidate: revalidateScoreTimeSeries,
    error: scoreError,
  } = useFetchSegments<GetScoreTimeSeriesResponseModel>(() =>
    insightsAPI.getScoreTimeSeries(score.uid, resolution, tzOffset),
  )

  const userTimeSeriesData =
    useFetchMultiSegments<GetScoreTimeSeriesResponseModel>(
      plottableUsers,
      () => insightsAPI.getScoreTimeSeries(score.uid, resolution, tzOffset),
      (state, userUid, periodDef, toFilterSearchParams) => {
        return toFilterSearchParams(
          {
            ...state.values,
            userUids: uniq((state.values.userUids ?? []).concat(userUid)),
          },
          periodDef,
        )
      },
    )

  const teamTimeSeriesData =
    useFetchMultiSegments<GetScoreTimeSeriesResponseModel>(
      plottableTeams,
      () => insightsAPI.getScoreTimeSeries(score.uid, resolution, tzOffset),
      (state, teamUid, periodDef, toFilterSearchParams) => {
        return toFilterSearchParams(
          {
            ...state.values,
            teamUids: uniq((state.values.teamUids ?? []).concat(teamUid)),
          },
          periodDef,
        )
      },
    )

  useOnRevalidateDataSource(DATA_SOURCE, () => {
    revalidateScoreTimeSeries()
    revalidateSummaries()
    userTimeSeriesData.forEach((x) => x.revalidate())
    teamTimeSeriesData.forEach((x) => x.revalidate())
  })

  const results = React.useMemo(() => {
    const buckets: Record<string, DateBucket> = {}

    const getOrCreateBucket = (date: Date): DateBucket => {
      const bucketKey = date.toISOString()
      if (buckets[bucketKey] === undefined) {
        buckets[bucketKey] = {
          date,
          users: {},
          teams: {},
        }
      }
      return buckets[bucketKey]
    }

    function addSegmentDataToBucket(
      segments: Segment<GetScoreTimeSeriesResponseModel | null>[],
      nestedProperty?: string,
    ): void {
      segments.forEach((s) => {
        const segmentKey = s.label
        const segmentColor = s.color
        ;(s.data?.series ?? []).forEach((x) => {
          const date = adjustDateForDSTOverlap(x.dateTime, tzOffset)

          // Omit weekend data?
          if (
            onlyWeekDays &&
            isWeekend(date) &&
            (resolution === 'Hours' || resolution === 'Days')
          ) {
            return
          }

          const bucket = getOrCreateBucket(date)

          let root = bucket
          if (nestedProperty !== undefined) {
            root = bucket[nestedProperty] ?? {}
            root[segmentKey] = root[segmentKey] ?? []
          }

          if (Array.isArray(root[segmentKey])) {
            root[segmentKey] = [...root[segmentKey], x]
          } else {
            root[segmentKey] = { ...x, segmentColor }
          }
        })
      })
    }

    // Add primary segment data
    addSegmentDataToBucket(segments)

    // Add user data
    userTimeSeriesData.forEach((userData) => {
      addSegmentDataToBucket(userData.segments, 'users')
    })

    teamTimeSeriesData.forEach((teamData) => {
      addSegmentDataToBucket(teamData.segments, 'teams')
    })

    const segmentLines = segments.reduce<LineDescriptor[]>((memo, s) => {
      // Only add lines when we also have data. Charts will do weird aninmations otherwise.
      if (s.data == null) return memo
      memo.push({
        id: `primary:${s.label}:${s.color}`,
        color: s.color,
        label: s.label,
        getValue: (d) => {
          const value = d[s.label]?.averageScore
          if (value == null) return null
          return value
        },
      })
      return memo
    }, [])

    const userLines = userTimeSeriesData.flatMap<LineDescriptor>(
      (userData, i) => {
        const variant = DashedLineVariants[i % DashedLineVariants.length]
        return userData.segments.map((s) => {
          const userUid = userData.source
          return {
            id: `secondary:${userUid}:${s.label}:${s.color}`,
            color: s.color,
            label: getUserByUid(userUid).name,
            getValue: (d) => {
              return d.users[s.label]?.[i]?.averageScore ?? null
            },
            variant,
          }
        })
      },
    )

    const teamLines = teamTimeSeriesData.flatMap<LineDescriptor>(
      (teamData, i) => {
        const variant = DashedLineVariants[i % DashedLineVariants.length]
        return teamData.segments.map((s) => {
          const teamUid = teamData.source
          return {
            id: `secondary:${teamUid}:${s.label}:${s.color}`,
            color: s.color,
            label: getTeamByUid(teamUid)?.name ?? teamUid,
            getValue: (d) => {
              return d.teams[s.label]?.[i]?.averageScore ?? null
            },
            variant,
          }
        })
      },
    )

    return {
      dataset: Object.values(buckets),
      segmentLines,
      userLines,
      teamLines,
    }
  }, [
    segments,
    userTimeSeriesData,
    teamTimeSeriesData,
    tzOffset,
    onlyWeekDays,
    resolution,
    getUserByUid,
    getTeamByUid,
  ])

  const referenceLines = React.useMemo<ReferenceLineDescriptor[]>(() => {
    return segmentSummaries.map<ReferenceLineDescriptor>((x) => ({
      color: x.color,
      label: x.label,
      y: x.data?.averageScore,
      formatValue: (value) =>
        i18n.number(value, {
          maximumFractionDigits: 1,
          minimumFractionDigits: 0,
        }),
    }))
  }, [segmentSummaries])

  // Check if any of the segments have non-empty date buckets
  const hasResults = segments.some((s) =>
    (s.data?.series ?? []).some((x) => x.conversations > 0),
  )

  if (!(isLoading || hasResults)) {
    return (
      <ContentPlaceholder.Container>
        <ContentPlaceholder.Image as={Icon_EmptyState} />
        <ContentPlaceholder.Heading>
          <Trans>No results</Trans>
        </ContentPlaceholder.Heading>
        <ContentPlaceholder.Body>
          <Trans>
            Try increasing the period in the top right corner or broadening your
            filtering
          </Trans>
        </ContentPlaceholder.Body>
      </ContentPlaceholder.Container>
    )
  }

  if (scoreError) return <DefaultFallbackComponent error={scoreError} />
  if (summaryError) return <DefaultFallbackComponent error={summaryError} />

  const formatAverageScore = (value: number): string =>
    i18n.number(value, {
      maximumFractionDigits: 1,
      minimumFractionDigits: 0,
    })

  return (
    <Box mt={4}>
      <Box w="100%" h="18rem">
        <LineChartSkeleton isLoaded={!isLoading}>
          <ResponsiveContainer width="100%" height="100%" minWidth={100}>
            <TimeSeriesChart
              data={results.dataset}
              resolution={resolution}
              margin={{
                top: 8,
                left: 0,
                bottom: 0,
                right: 50,
              }}
            >
              <CartesianGrid />
              <Tooltip
                content={
                  <TimeSeriesTooltip
                    resolution={resolution}
                    formatValue={(value, name, d) => {
                      const { conversations } = d[name]
                      const formattedAverageScore = formatAverageScore(value)
                      return t`${formattedAverageScore} (${conversations} conversations)`
                    }}
                    formatSecondaryValue={formatAverageScore}
                    secondaryType="users"
                  />
                }
              />
              <XAxis dataKey="date" />
              <YAxis
                valueType="value"
                width={50}
                domain={[0, score.maxScore]}
              />
              <Customized
                component={
                  <GoalReferenceArea goal={score.goal} style="number" />
                }
              />
              {results.segmentLines.map((line) => (
                <PrimaryLine key={line.id} line={line} />
              ))}
              {results.userLines.map((line) => (
                <Line key={line.id} line={line} />
              ))}
              {results.teamLines.map((line) => (
                <Line key={line.id} line={line} />
              ))}
              {referenceLines.map((line) => (
                <ReferenceLine key={line.label} line={line} />
              ))}
            </TimeSeriesChart>
          </ResponsiveContainer>
        </LineChartSkeleton>
      </Box>
      <Flex justify="flex-end" mt={4}>
        <ScoreSummaryList
          segments={segmentSummaries}
          onViewConversations={onViewConversations}
          goal={score.goal}
        />
      </Flex>
      {currentUser.isAdminOrTeamLead ? (
        <Tabs
          index={tabIndex}
          onChange={(index) => {
            selectedBreakdownViewStore.setState(() => getTab(index).value)
          }}
          isLazy
          mt={4}
        >
          <TabList>
            {tabs.map((t) => (
              <Tab key={t.label}>{t.label}</Tab>
            ))}
          </TabList>
          <TabPanels>
            {tabs.map((t) => (
              <TabPanel key={t.label} px={0}>
                {t.content}
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      ) : (
        <Box>
          <SectionHeading as="h3" m="0">
            <Trans>Criteria</Trans>
          </SectionHeading>
          <ParameterHitsList score={score} />
        </Box>
      )}
    </Box>
  )
}
