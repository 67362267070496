import {
  GetScoreDistributionResponseModel,
  ScoreSummary,
  insightsAPI,
} from '@capturi/api-insights'
import { Score } from '@capturi/api-scoring'
import Icon_EmptyState from '@capturi/assets/images/EmptyState.svg'
import { BarChartSkeleton } from '@capturi/charts'
import { useCurrentUser } from '@capturi/core'
import {
  Segment,
  useFetchSegments,
  useFilterPeriodContext,
  useFirstPhoneSegmentState,
  useSegmentStatesContext,
} from '@capturi/filters'
import { DefaultFallbackComponent } from '@capturi/react-utils'
import { ContentPlaceholder, SectionHeading } from '@capturi/ui-components'
import {
  Box,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react'
import { i18n } from '@lingui/core'
import { Trans, t } from '@lingui/macro'
import { useStore } from '@tanstack/react-store'
import isEmpty from 'lodash/isEmpty'
import NoAccessibleTrackers from 'pages/analytics/shared/components/NoAccessibleTrackers'
import TeamFilterRequiredMessage from 'pages/analytics/shared/components/TeamFilterRequiredMessage'
import React from 'react'
import { Bar, Tooltip } from 'recharts'

import { DATA_SOURCE } from '../../constants'
import { useScoreConversationsDrawer } from '../../drawers'
import { logEvent } from '../../events'
import { useOnRevalidateDataSource } from '../../hooks/useRevalidateDataSource'
import { useTabsState } from '../../hooks/useTabsState'
import {
  BreakdownViewtype,
  selectedBreakdownViewStore,
} from '../../state/selectedBreakdownView'
import ParameterHitsList from '../ParameterHitsList'
import { ScoreSummaryList } from '../ScoreSummaryList'
import TeamHitsList from '../TeamHitsList'
import UserHitsList from '../UserHitsList'
import {
  RangeAwareBarShape,
  ScoreDistributionChart,
} from './ScoreDistributionChart'
import ScoreDistributionTooltip from './ScoreDistributionTooltip'
import { useScoreDistributionData } from './useScoreDistributionData'

export type ScoreDistributionViewProps = {
  score: Score
}

export const ScoreDistributionView: React.FC<ScoreDistributionViewProps> = ({
  score,
}) => {
  const currentUser = useCurrentUser()
  const { periodDef } = useFilterPeriodContext()
  const { phoneSegmentStates: states } = useSegmentStatesContext()
  const openConversationsDrawer = useScoreConversationsDrawer()

  // Stable range after dragging range slider - used for data fetching.
  const [selectedRange, setSelectedRange] = React.useState(() => {
    return [0, score.maxScore]
  })

  const { getFilterRequestModel } = useFirstPhoneSegmentState()

  const filter = getFilterRequestModel()

  const onViewConversations = (
    index: number,
    segment: Segment<ScoreSummary>,
  ): void => {
    openConversationsDrawer({
      score,
      selectedRange,
      periodDef,
      filterState: states[index].values,
      excludeDeletedConversations: false,
      segmentColorScheme: segment.color,
    })
    logEvent('view-conversations-drawer--clicked')
  }

  const {
    segments: segmentSummaries,
    revalidate: revalidateSummaries,
    error: summaryError,
  } = useFetchSegments<ScoreSummary>(
    () => insightsAPI.getScoreSummary(score.uid),
    (state, _, periodDef, mapperFn) => {
      return mapperFn(
        {
          ...state.values,
          scores: [
            ...(state.values.scores ?? []),
            {
              uid: score.uid,
              min: selectedRange?.[0],
              max: selectedRange?.[1],
            },
          ],
        },
        periodDef,
      )
    },
  )

  const selectedBeakdownView = useStore(selectedBreakdownViewStore)

  const { tabIndex, getTab, tabs } = useTabsState<{
    label: string
    value: BreakdownViewtype
    content: React.ReactElement
  }>(() => {
    return [
      {
        label: t`Criteria`,
        value: 'criteria',
        content: (
          <ParameterHitsList score={score} selectedRange={selectedRange} />
        ),
      },
      {
        label: t`Employees`,
        value: 'employees',
        content: (
          <UserHitsList
            score={score}
            selectedRange={selectedRange}
            segmentSummaries={segmentSummaries}
          />
        ),
      },
      {
        label: t`Teams`,
        value: 'teams',
        content: (
          <TeamHitsList
            score={score}
            selectedRange={selectedRange}
            segmentSummaries={segmentSummaries}
          />
        ),
      },
    ]
  }, selectedBeakdownView)

  const {
    segments,
    isLoading,
    revalidate,
    error: distributionError,
  } = useFetchSegments<GetScoreDistributionResponseModel>(() =>
    insightsAPI.getScoreDistribution(score.uid),
  )

  const hasNoResults =
    !isLoading && segments.every((x) => isEmpty(x.data?.scores))

  useOnRevalidateDataSource(DATA_SOURCE, () => {
    revalidate()
    revalidateSummaries()
  })

  const { data, segmentBars } = useScoreDistributionData(score, segments)

  if (
    currentUser.isTeamLead &&
    !filter.teamUids &&
    distributionError?.statusCode === 403
  )
    return <TeamFilterRequiredMessage />

  if (distributionError && distributionError.statusCode === 403)
    return <NoAccessibleTrackers />

  if (hasNoResults) {
    return (
      <ContentPlaceholder.Container>
        <ContentPlaceholder.Image as={Icon_EmptyState} />
        <ContentPlaceholder.Heading>
          <Trans>No results</Trans>
        </ContentPlaceholder.Heading>
        <ContentPlaceholder.Body>
          <Trans>
            Try increasing the period in the top right corner or broadening your
            filtering
          </Trans>
        </ContentPlaceholder.Body>
      </ContentPlaceholder.Container>
    )
  }

  if (distributionError)
    return <DefaultFallbackComponent error={distributionError} />
  if (summaryError) return <DefaultFallbackComponent error={summaryError} />

  return (
    <Box>
      <Box h="18rem">
        <BarChartSkeleton isLoaded={!isLoading}>
          <ScoreDistributionChart
            data={data}
            range={selectedRange}
            onRangeChange={(range) => {
              setSelectedRange(range)
              logEvent('distribution-chart--score-range-changed')
            }}
            onScoreBarClick={(score) => {
              setSelectedRange([score, score])
              logEvent('distribution-chart--bar-clicked', {
                view: 'main',
              })
            }}
            showYAxis
          >
            <Tooltip
              content={
                <ScoreDistributionTooltip
                  formatValue={(p) => {
                    const segmentData = p.payload[p.name]
                    const formattedValue = i18n.number(p.value, {
                      style: 'percent',
                      minimumFractionDigits: 1,
                      maximumFractionDigits: 1,
                    })
                    const hits = i18n.number(segmentData.count)
                    const total = i18n.number(segmentData.total)
                    return t`${formattedValue} (${hits}/${total} conversations)`
                  }}
                />
              }
            />
            {segmentBars.map((line) => (
              <Bar
                id={line.label}
                key={line.label}
                name={line.label}
                dataKey={line.getValue}
                fill={line.color}
                shape={<RangeAwareBarShape />}
              />
            ))}
          </ScoreDistributionChart>
        </BarChartSkeleton>
      </Box>
      <Flex justify="flex-end" mt={4}>
        <ScoreSummaryList
          segments={segmentSummaries}
          onViewConversations={onViewConversations}
          goal={score.goal}
        />
      </Flex>

      {currentUser.isAdminOrTeamLead ? (
        <Tabs
          index={tabIndex}
          onChange={(index) => {
            const newTabValue = getTab(index).value
            selectedBreakdownViewStore.setState(() => newTabValue)
            logEvent('breakdown-view--changed', { view: newTabValue })
          }}
          isLazy
          mt={4}
        >
          <TabList>
            {tabs.map((t) => (
              <Tab key={t.label}>{t.label}</Tab>
            ))}
          </TabList>
          <TabPanels>
            {tabs.map((t) => (
              <TabPanel key={t.label} px={0}>
                {t.content}
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      ) : (
        <Box>
          <SectionHeading as="h3" m="0">
            <Trans>Criteria</Trans>
          </SectionHeading>
          <ParameterHitsList score={score} selectedRange={selectedRange} />
        </Box>
      )}
    </Box>
  )
}
