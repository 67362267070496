import analytics from '@capturi/analytics'
import {
  ConversationsInclScoresResponseModel,
  useConversationsIncludingScores,
} from '@capturi/api-conversations'
import { PhoneFilterValues } from '@capturi/api-filters'
import {
  GetScoreDistributionResponseModel,
  GetScoreParametersResponseModel,
  insightsAPI,
} from '@capturi/api-insights'
import { Score } from '@capturi/api-scoring'
import { useAPI } from '@capturi/api-utils'
import { AudioProvider, useAudioContext } from '@capturi/audio'
import { BarChartSkeleton } from '@capturi/charts'
import { useCurrentUser } from '@capturi/core'
import {
  FilterPeriodProvider,
  PeriodDefinition,
  SegmentStatesProvider,
  toFilterSearchParams,
  useFilterPeriodContext,
  useFirstPhoneSegmentState,
} from '@capturi/filters'
import { ScoreParameterBreakdown } from '@capturi/scoring'
import { useUsers } from '@capturi/stores'
import {
  List,
  ListItemSecondaryAction,
  ListSkeleton,
} from '@capturi/ui-components'
import {
  Badge,
  Box,
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  DrawerProps,
  Flex,
  HStack,
  Icon,
  IconButton,
  Text,
  useToken,
} from '@chakra-ui/react'
import { i18n } from '@lingui/core'
import { Trans, t } from '@lingui/macro'
import { useConversationDetailsDrawer } from 'components/ConversationDetailsDrawer'
import OptionalAudioPlayerActions from 'components/OptionalAudioPlayerActions'
import { PlayListItem } from 'components/PlayList'
import sumBy from 'lodash/sumBy'
import React, { useCallback } from 'react'
import { MdLaunch } from 'react-icons/md'
import { Bar, Tooltip } from 'recharts'

import IsReviewedCheckmark from '../../Conversations/ConversationsPage/isReviewedCheckmark.svg'
import {
  RangeAwareBarShape,
  ScoreBucket,
  ScoreDistributionChart,
} from '../components/ScoreDistributionView/ScoreDistributionChart'
import ScoreDistributionTooltip from '../components/ScoreDistributionView/ScoreDistributionTooltip'
import { logEvent } from '../events'

const dateFormat: Intl.DateTimeFormatOptions = {
  month: 'short',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
}

type FetchOptions = {
  excludeDeletedConversations?: boolean
}

type DataProps = {
  score: Score
  selectedRange?: number[]
  filterState: PhoneFilterValues
  periodDef: PeriodDefinition
  label?: string
  title?: string
  segmentColorScheme?: string
} & FetchOptions

export type ScoreConversationsDrawerProps = DataProps &
  Omit<DrawerProps, 'children'>

export const ScoreConversationsDrawer: React.FC<
  ScoreConversationsDrawerProps
> = ({
  score,
  selectedRange: initialSelectedRange,
  filterState,
  periodDef,
  label = t`Audio snippets`,
  title = score.name,
  segmentColorScheme,
  excludeDeletedConversations,
  isOpen,
  onClose,
}) => {
  const [selectedRange, setSelectedRange] = React.useState(initialSelectedRange)

  const { data, error } = useAPI<GetScoreDistributionResponseModel>(
    insightsAPI.getScoreDistribution(score.uid),
    () => {
      return toFilterSearchParams(filterState, periodDef)
    },
    {
      suspense: false,
    },
  )

  const isLoading = !(data || error)

  const chartData = React.useMemo<ScoreBucket[]>(() => {
    const scores = data?.scores ?? []
    const sumCount = sumBy(scores, (x) => x.count)
    return scores
      .filter((x) => x.score <= score.maxScore)
      .map((x) => ({
        ...x,
        valuePct: sumCount > 0 ? x.count / sumCount : 0,
        total: sumCount,
      }))
  }, [data, score])

  const [segmentColor] = useToken('colors', [
    `${segmentColorScheme ?? 'segments.primary'}.500`,
  ])

  return (
    <ProvidersContainer periodDef={periodDef} filterState={filterState}>
      <Drawer isOpen={isOpen} onClose={onClose} placement="right" size="md">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader
            borderBottom="1px"
            borderBottomColor="gray.200"
            lineHeight={1.4}
            display="flex"
            alignItems="center"
            pr={10}
          >
            <Box flex="1">
              <Text fontSize="sm" color="textMuted">
                {label}
              </Text>
              <Text fontSize="1rem">{title}</Text>
            </Box>
          </DrawerHeader>
          <DrawerBody p={0} display="flex" flexDirection="column">
            <Box flex="0 0 10rem" px={4}>
              <BarChartSkeleton isLoaded={!isLoading}>
                <ScoreDistributionChart
                  data={chartData}
                  range={selectedRange}
                  onRangeChange={setSelectedRange}
                  onScoreBarClick={(score) => {
                    setSelectedRange([score, score])
                    logEvent('distribution-chart--bar-clicked', {
                      view: 'conversations-drawer',
                    })
                  }}
                >
                  <Tooltip
                    content={
                      <ScoreDistributionTooltip
                        formatValue={(p) => {
                          const formattedValue = i18n.number(p.value, {
                            style: 'percent',
                            minimumFractionDigits: 1,
                            maximumFractionDigits: 1,
                          })
                          const hits = i18n.number(p.payload.count)
                          const total = i18n.number(p.payload.total)
                          return t`${formattedValue} (${hits}/${total} conversations)`
                        }}
                      />
                    }
                  />
                  <Bar
                    dataKey="valuePct"
                    fill={segmentColor}
                    shape={<RangeAwareBarShape />}
                  />
                </ScoreDistributionChart>
              </BarChartSkeleton>
            </Box>
            <Text fontWeight="medium" px={6} mt={8} mb={2}>
              <Trans>Criteria</Trans>
            </Text>
            <Box px={6}>
              <React.Suspense
                fallback={
                  <ListSkeleton
                    rowCount={score.parameters.length}
                    colCount={3}
                  />
                }
              >
                <ScoreParameterHits
                  score={score}
                  selectedRange={selectedRange}
                  colorScheme={segmentColorScheme}
                />
              </React.Suspense>
            </Box>
            <Text fontWeight="medium" px={6} mt={8} mb={2}>
              <Trans>Conversations</Trans>
            </Text>
            <Box flex={1} overflow="hidden">
              <DrawerBodyContent
                score={score}
                selectedRange={selectedRange}
                excludeDeletedConversations={excludeDeletedConversations}
              />
            </Box>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </ProvidersContainer>
  )
}

export const ProvidersContainer: React.FC<
  Pick<ScoreConversationsDrawerProps, 'periodDef' | 'filterState'> & {
    children?: React.ReactNode
  }
> = ({ children, filterState, periodDef }) => (
  <FilterPeriodProvider defaultPeriod={periodDef}>
    <SegmentStatesProvider
      initialState={{
        channel: 'phone',
        state: {
          values: filterState,
          savedFilter: undefined,
        },
      }}
    >
      <AudioProvider>
        <React.Suspense fallback={<div />}>{children}</React.Suspense>
      </AudioProvider>
    </SegmentStatesProvider>
  </FilterPeriodProvider>
)

const ScoreParameterHits: React.FC<{
  score: Score
  selectedRange?: number[]
  colorScheme?: string
}> = ({ score, selectedRange, colorScheme }) => {
  const { periodDef } = useFilterPeriodContext()
  const segmentState = useFirstPhoneSegmentState()

  const { data } = useAPI<GetScoreParametersResponseModel>(
    insightsAPI.getScoreParameters(score.uid),
    () => {
      const filterValues: Partial<PhoneFilterValues> = {
        ...(segmentState.activeValues ?? {}),
        scores: [
          ...(segmentState.activeValues?.scores ?? []),
          {
            uid: score.uid,
            min: selectedRange?.[0],
            max: selectedRange?.[1],
          },
        ],
      }
      return toFilterSearchParams(filterValues, periodDef)
    },
  )

  if (data == null) return null
  return (
    <ScoreParameterBreakdown
      parameters={score.parameters}
      hits={data.parameters}
      conversationsTotal={data.conversationsTotal}
      colorScheme={colorScheme}
    />
  )
}

const getScoreByUid = (
  activeValues: PhoneFilterValues | undefined,
  score: Score,
  periodDef: PeriodDefinition,
  selectedRange?: number[],
): Partial<PhoneFilterValues> => {
  const values = {
    ...(activeValues ?? {}),
    scores: [
      ...(activeValues?.scores ?? []),
      {
        uid: score.uid,
        min: selectedRange?.[0],
        max: selectedRange?.[1],
      },
    ],
  }
  return toFilterSearchParams(values, periodDef)
}

export const DrawerBodyContent: React.FC<
  { score: Score; selectedRange?: number[] } & FetchOptions
> = ({ score, selectedRange }) => {
  const segmentState = useFirstPhoneSegmentState()
  const { periodDef } = useFilterPeriodContext()

  const { data, fetchNextPage, isLoading, hasNextPage, isFetchingNextPage } =
    useConversationsIncludingScores(
      getScoreByUid(segmentState.activeValues, score, periodDef, selectedRange),
    )

  const handleLoadMore = (): void => {
    fetchNextPage()
  }

  return (
    <Box h="full" overflow="auto">
      <List disablePadding>
        {(data?.pages ?? []).map((page) =>
          page?.conversations.map((c) => (
            <ConversationWithScore key={c.uid} score={score} conversation={c} />
          )),
        )}
      </List>
      {!isLoading && (
        <Flex my={4} justify="center" w="100%">
          <Button
            onClick={handleLoadMore}
            isDisabled={!hasNextPage || isFetchingNextPage}
            isLoading={isFetchingNextPage}
            borderRadius="md"
            size="sm"
          >
            {!hasNextPage ? (
              <Trans>No more conversations</Trans>
            ) : (
              <Trans>Load more</Trans>
            )}
          </Button>
        </Flex>
      )}
    </Box>
  )
}

export type ConversationWithScoreProps = {
  score: Score
  conversation: ConversationsInclScoresResponseModel['conversations'][0]
}
export const ConversationWithScore: React.FC<ConversationWithScoreProps> = ({
  score,
  conversation,
}) => {
  const {
    uid,
    dateTime,
    userUid,
    customer,
    subject,
    deleted,
    duration,
    qaIsReviewed,
  } = conversation
  const user = useCurrentUser()
  const { getUserByUid } = useUsers()
  const { getTime, pause, playbackContext } = useAudioContext()

  const openConversationDetailsDrawer = useConversationDetailsDrawer()
  const onGoToConversation = useCallback(
    (uid: string): void => {
      React.startTransition(() => {
        let initialAudioTimestamp: number | undefined
        if (playbackContext?.conversationUid === uid) {
          initialAudioTimestamp = getTime()
          pause()
        }
        openConversationDetailsDrawer({
          uid,
          initialAudioTimestamp,
          initialTab: 'scoring',
        })
      })
    },
    [
      getTime,
      openConversationDetailsDrawer,
      pause,
      playbackContext?.conversationUid,
    ],
  )

  return (
    <PlayListItem
      hasAudio={conversation.hasAudio}
      key={uid}
      context={{ conversationUid: uid }}
      timestamp={0}
      showAudioPlayer
      onPlay={() =>
        analytics.event('analytics_Conversations_PlayAudio', {
          uid,
          date: dateTime.toUTCString(),
        })
      }
      additionalActions={(props) => (
        <OptionalAudioPlayerActions
          {...props}
          duration={duration}
          conversationUid={uid}
        />
      )}
      isDisabled={!user.permissions.playback || deleted}
    >
      <Box w="full" minW={0}>
        <Flex align="center">
          <Text fontSize="md" fontWeight="medium">
            {customer}
          </Text>
          <Divider orientation="vertical" mx={2} minH={3} />
          <HStack gap={0}>
            <Text noOfLines={1} wordBreak="break-all" title={subject} flex={1}>
              {subject}
            </Text>
            {user.permissions.qualityAssurance && qaIsReviewed && (
              <Icon boxSize="5" as={IsReviewedCheckmark} />
            )}
          </HStack>
          {deleted && (
            <Badge colorScheme="red">
              <Trans>Deleted</Trans>
            </Badge>
          )}
        </Flex>
        <Flex fontSize="sm" color="gray.600" align="center">
          <Text as="span" flexShrink={0}>
            {i18n.date(dateTime, dateFormat)}
          </Text>
          <Divider orientation="vertical" mx={2} minH={3} />
          <Text>{getUserByUid(userUid).name}</Text>
          <Text flex="1 0 auto" textAlign="right" pl={2}>
            <Trans>Score: {conversation.scores[score.uid] ?? '?'}</Trans>
          </Text>
        </Flex>
      </Box>
      <ListItemSecondaryAction>
        <IconButton
          aria-label={t`Go to conversation`}
          title={t`Go to conversation`}
          variant="ghost"
          size="xs"
          icon={<MdLaunch />}
          onClick={() => onGoToConversation(uid)}
        />
      </ListItemSecondaryAction>
    </PlayListItem>
  )
}
