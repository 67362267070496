import { useConversationsIncludingScores } from '@capturi/api-conversations'
import { PhoneFilterValues } from '@capturi/api-filters'
import { Score, ScoreParameter } from '@capturi/api-scoring'
import { useAudioShortcut } from '@capturi/audio'
import {
  PeriodDefinition,
  toFilterSearchParams,
  useFilterPeriodContext,
  useFirstPhoneSegmentState,
} from '@capturi/filters'
import { getFilterInfo } from '@capturi/scoring'
import { List } from '@capturi/ui-components'
import {
  OnChangeValue,
  OptionProps,
  PopoutSelect,
  SelectOption,
  components,
} from '@capturi/ui-select'
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  DrawerProps,
  Flex,
  Icon,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Text,
} from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import React from 'react'
import { MdArrowDropDown } from 'react-icons/md'

import {
  ConversationWithScore,
  ProvidersContainer,
} from './ConversationsDrawer'

type FetchOptions = {
  excludeDeletedConversations?: boolean
}

type DataProps = {
  score: Score
  parameterUid: string
  parameterHasScore?: boolean
  selectedRange?: number[]
  filterState: PhoneFilterValues
  periodDef: PeriodDefinition
} & FetchOptions

export type ParameterConversationsDrawerProps = DataProps &
  Omit<DrawerProps, 'children'>

export const ParameterConversationsDrawer: React.FC<
  ParameterConversationsDrawerProps
> = ({
  score,
  parameterUid: initialParameterUid,
  parameterHasScore: initialParameterHasScore = true,
  selectedRange,
  filterState,
  periodDef,
  excludeDeletedConversations,
  isOpen,
  onClose,
}) => {
  const [parameterHasScore, setParameterHasScore] = React.useState(
    initialParameterHasScore,
  )
  const [parameterUid, setParameterUid] = React.useState(initialParameterUid)
  const parameter = score.parameters.find((x) => x.uid === parameterUid)
  return (
    <ProvidersContainer periodDef={periodDef} filterState={filterState}>
      <Drawer isOpen={isOpen} onClose={onClose} placement="right" size="md">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader
            borderBottom="1px"
            borderBottomColor="gray.200"
            lineHeight={1.4}
            display="flex"
            alignItems="center"
            pr={10}
          >
            <Box flex="1">
              <Box>
                <ParameterHasScorePopoverSelect
                  value={parameterHasScore}
                  onChange={(parameterHasScore) =>
                    setParameterHasScore(parameterHasScore)
                  }
                >
                  <Button
                    variant="ghost"
                    px={2}
                    height="fit-content"
                    rightIcon={<Icon as={MdArrowDropDown} boxSize={5} />}
                  >
                    {parameterHasScore ? (
                      <Trans>Conversations with</Trans>
                    ) : (
                      <Trans>Conversations without</Trans>
                    )}
                  </Button>
                </ParameterHasScorePopoverSelect>
              </Box>
              <ParameterPopoverSelect
                options={score.parameters}
                value={parameter}
                onChange={(parameter) => setParameterUid(parameter.uid)}
              >
                <Button
                  variant="ghost"
                  px={2}
                  height="fit-content"
                  rightIcon={<Icon as={MdArrowDropDown} boxSize={5} />}
                  size="md"
                  w="80%"
                >
                  <Text
                    overflow="hidden"
                    whiteSpace="nowrap"
                    textOverflow="ellipsis"
                  >
                    {parameter && getFilterInfo(parameter).label}
                  </Text>
                </Button>
              </ParameterPopoverSelect>
            </Box>
          </DrawerHeader>
          <DrawerBody overflow="hidden" p={0}>
            <DrawerBodyContent
              score={score}
              parameterUid={parameterUid}
              parameterHasScore={parameterHasScore}
              selectedRange={selectedRange}
              excludeDeletedConversations={excludeDeletedConversations}
            />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </ProvidersContainer>
  )
}

const getScoreByUid = (
  activeValues: PhoneFilterValues | undefined,
  score: Score,
  periodDef: PeriodDefinition,
  parameterUid: string,
  parameterHasScore: boolean,
  selectedRange?: number[],
): Partial<PhoneFilterValues> => {
  const values = {
    ...(activeValues ?? {}),
    scores: [
      ...(activeValues?.scores ?? []),
      {
        uid: score.uid,
        min: selectedRange?.[0],
        max: selectedRange?.[1],
        parameterUid: parameterUid,
        parameterHasScore: parameterHasScore,
      },
    ],
  }
  return toFilterSearchParams(values, periodDef)
}

const DrawerBodyContent: React.FC<
  {
    score: Score
    parameterUid: string
    parameterHasScore: boolean
    selectedRange?: number[]
  } & FetchOptions
> = ({ score, parameterUid, parameterHasScore, selectedRange }) => {
  const segmentState = useFirstPhoneSegmentState()
  const { periodDef } = useFilterPeriodContext()
  useAudioShortcut()

  const { data, fetchNextPage, isLoading, hasNextPage, isFetchingNextPage } =
    useConversationsIncludingScores(
      getScoreByUid(
        segmentState.activeValues,
        score,
        periodDef,
        parameterUid,
        parameterHasScore,
        selectedRange,
      ),
    )

  const handleLoadMore = (): void => {
    fetchNextPage()
  }

  return (
    <Box h="full" overflow="auto">
      <List disablePadding>
        {(data?.pages ?? []).map((page) =>
          page?.conversations.map((c) => (
            <ConversationWithScore key={c.uid} score={score} conversation={c} />
          )),
        )}
      </List>
      {!isLoading && (
        <Flex my={4} justify="center" w="100%">
          <Button
            onClick={handleLoadMore}
            isDisabled={!hasNextPage || isFetchingNextPage}
            isLoading={isFetchingNextPage}
            borderRadius="md"
            size="sm"
          >
            {!hasNextPage ? (
              <Trans>No more conversations</Trans>
            ) : (
              <Trans>Load more</Trans>
            )}
          </Button>
        </Flex>
      )}
    </Box>
  )
}

const ParameterHasScorePopoverSelect: React.FC<{
  value: boolean
  onChange: (value: boolean) => void
  children?: React.ReactNode
}> = ({ value: valueProp, onChange, children }) => {
  const options: SelectOption[] = [
    {
      value: 'true',
      label: t`View conversations WITH the criteria`,
    },
    {
      value: 'false',
      label: t`View conversations WITHOUT the criteria`,
    },
  ]
  const value = options.find((x) => x.value === String(valueProp))
  return (
    <Popover placement="auto-start">
      {({ onClose }) => (
        <>
          <PopoverTrigger>{children}</PopoverTrigger>
          <PopoverContent>
            <PopoutSelect
              options={options}
              value={value}
              onChange={(option) => {
                onChange((option as SelectOption).value === 'true')
                onClose?.()
              }}
            />
          </PopoverContent>
        </>
      )}
    </Popover>
  )
}

type ScoreParameterOption = SelectOption & {
  parameter?: ScoreParameter
}

const Option: React.ComponentType<
  OptionProps<ScoreParameterOption, boolean>
> = (props) => {
  const { parameter } = props.data as ScoreParameterOption
  const filterInfo = parameter ? getFilterInfo(parameter) : undefined
  return (
    <components.Option {...props}>
      <Box noOfLines={1} wordBreak="break-all">
        {filterInfo ? filterInfo.label : '?'}
      </Box>
    </components.Option>
  )
}

const ParameterPopoverSelect: React.FC<{
  options: ScoreParameter[]
  value?: ScoreParameter
  onChange: (value: ScoreParameter) => void
  children?: React.ReactNode
}> = ({ options: optionsProp, value: valueProp, onChange, children }) => {
  const { options, value } = React.useMemo(() => {
    const options = optionsProp.reduce<ScoreParameterOption[]>((memo, x) => {
      if (x.uid) {
        memo.push({
          value: x.uid,
          label: x.type,
          parameter: x,
        })
      }
      return memo
    }, [])
    const value = options.find((x) => x.value === valueProp?.uid)
    return {
      options,
      value,
    }
  }, [optionsProp, valueProp])

  const handleChange = (
    option: OnChangeValue<ScoreParameterOption, boolean>,
  ): void => {
    const parameter = (option as ScoreParameterOption)?.parameter
    if (parameter) {
      onChange(parameter)
    }
  }

  return (
    <Popover placement="auto-start">
      {({ onClose }) => (
        <>
          <PopoverTrigger>{children}</PopoverTrigger>
          <PopoverContent>
            <PopoutSelect
              placeholder={t`Search...`}
              options={options}
              value={value}
              onChange={(value) => {
                handleChange(value)
                onClose?.()
              }}
              components={{
                Option,
              }}
            />
          </PopoverContent>
        </>
      )}
    </Popover>
  )
}
