import Icon_EmptyState from '@capturi/assets/images/EmptyState.svg'
import { useCurrentUser } from '@capturi/core'
import { ContentPlaceholder } from '@capturi/ui-components'
import { Text } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { ButtonLink } from 'components/Link'
import React from 'react'
import { MdAdd } from 'react-icons/md'

import { routes } from '../routes'

export const NoScoresPlaceholder: React.FC = () => {
  const user = useCurrentUser()
  return (
    <ContentPlaceholder.Container mt={20}>
      <ContentPlaceholder.Image as={Icon_EmptyState} />
      <ContentPlaceholder.Heading>
        <Trans>It&apos;s a bit empty here!</Trans>
      </ContentPlaceholder.Heading>
      <ContentPlaceholder.Body>
        <Text>
          <Trans>
            Scoring can help you get a combined overview of the central areas
            that are important for your organisation.
          </Trans>
        </Text>
        {user.isAdminOrTeamLead && (
          <Text mt={4}>
            <Trans>
              Create your first score so you can improve your conversations.
            </Trans>
          </Text>
        )}
      </ContentPlaceholder.Body>
      {user.isAdminOrTeamLead && (
        <ContentPlaceholder.Footer>
          <ButtonLink primary to={routes.createScore()} leftIcon={<MdAdd />}>
            <Trans>Create score</Trans>
          </ButtonLink>
        </ContentPlaceholder.Footer>
      )}
    </ContentPlaceholder.Container>
  )
}
