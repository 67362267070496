import clamp from 'lodash/clamp'

type BaseTab = {
  label: string
  value: string
}

export type UseTabsState<T extends BaseTab> = {
  tabIndex: number
  getTab: (index: number) => T
  tabs: T[]
}

export function useTabsState<T extends BaseTab>(
  options: T[] | (() => T[]),
  value?: T['value'],
): UseTabsState<T> {
  const tabsOptions = typeof options === 'function' ? options() : options
  const tabIndex = clamp(
    tabsOptions.findIndex((o) => o.value === value),
    0,
    tabsOptions.length - 1,
  )
  return {
    tabIndex,
    getTab: (index) => tabsOptions[index],
    tabs: tabsOptions,
  }
}
