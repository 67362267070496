import { i18n } from '@lingui/core'

export const formatHitRate = (value?: number): string => {
  if (value === undefined) return ''
  return i18n.number(value, {
    style: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits: value < 0.01 ? 1 : 0,
  })
}
