import { useFilterPeriodContext } from '@capturi/filters'
import React from 'react'
import { useInterval, usePrevious } from 'react-use'

import { DATA_SOURCE, POLL_INTERVAL } from '../constants'
import { useRevalidateDataSource } from './useRevalidateDataSource'

export function useIsProcessingCurrentPeriod(
  isProcessing: boolean,
  processingProgress: Date | null,
): boolean {
  const { period } = useFilterPeriodContext()
  const isProcessingCurrentPeriod =
    isProcessing &&
    (processingProgress === null || // Processing not started yet, but will start soon
      (processingProgress != null && processingProgress > period.from)) // Processed enough to show current period
  const wasProcessing = usePrevious(isProcessingCurrentPeriod)
  const triggerRevalidation = useRevalidateDataSource(DATA_SOURCE)

  useInterval(
    () => {
      triggerRevalidation()
    },
    isProcessingCurrentPeriod ? POLL_INTERVAL : null,
  )

  React.useEffect(() => {
    if (!isProcessingCurrentPeriod && wasProcessing) {
      // We are going to need to update data one last time after the score is no longer dirty.
      triggerRevalidation()
    }
  }, [isProcessingCurrentPeriod, triggerRevalidation, wasProcessing])

  return isProcessingCurrentPeriod
}
