import {
  GetScoreSummariesResponseModel,
  ScoreSummary,
  insightsAPI,
} from '@capturi/api-insights'
import { useAPI } from '@capturi/api-utils'
import { toFilterSearchParams, useFilterPeriodContext } from '@capturi/filters'
import { ResponseError } from '@capturi/request'
import React from 'react'
import { SWRConfiguration } from 'swr'

import { POLL_INTERVAL } from '../constants'

interface UseScoreSummariesReturn {
  summaries: { [uid: string]: ScoreSummary }
  isLoading: boolean
  error: ResponseError | undefined
}

export function useScoreSummaries(
  swrConfig: SWRConfiguration = {},
): UseScoreSummariesReturn {
  const { periodDef } = useFilterPeriodContext()
  const { data, error } = useAPI<GetScoreSummariesResponseModel>(
    insightsAPI.getScoreSummaries(),
    () => toFilterSearchParams({}, periodDef),
    {
      refreshInterval: POLL_INTERVAL,
      ...swrConfig,
    },
  )

  return React.useMemo(() => {
    const dict = (data?.scores ?? []).reduce<{
      [uid: string]: ScoreSummary
    }>((acc, x) => {
      acc[x.uid] = x
      return acc
    }, {})
    return {
      summaries: dict,
      isLoading: !(data || error),
      error,
    }
  }, [data, error])
}
