import { GetScoreDistributionResponseModel } from '@capturi/api-insights'
import { Score } from '@capturi/api-scoring'
import { LineDescriptor } from '@capturi/charts'
import { Segment } from '@capturi/filters'
import { useTheme } from '@chakra-ui/react'
import { getColor } from '@chakra-ui/theme-tools'
import sumBy from 'lodash/sumBy'
import React from 'react'

import { ScoreBucket } from './ScoreDistributionChart'

export function useScoreDistributionData(
  score: Score,
  segments: Segment<GetScoreDistributionResponseModel>[],
): {
  data: ScoreBucket[]
  segmentBars: LineDescriptor[]
} {
  const theme = useTheme()
  return React.useMemo(() => {
    const buckets: Record<number, ScoreBucket> = {}

    const getOrCreateBucket = (score: number): ScoreBucket => {
      if (buckets[score] === undefined) {
        buckets[score] = {
          score,
        }
      }
      return buckets[score]
    }

    function addSegmentDataToBucket(
      segments: Segment<GetScoreDistributionResponseModel | null>[],
    ): void {
      segments.forEach((segment) => {
        const sumCount = sumBy(segment.data?.scores ?? [], (x) => x.count)
        ;(segment.data?.scores ?? []).forEach((x) => {
          if (x.score > score.maxScore) {
            return
          }
          const bucket = getOrCreateBucket(x.score)
          bucket[segment.label] = {
            ...x,
            valuePct: sumCount > 0 ? x.count / sumCount : 0,
            total: sumCount,
          }
        })
      })
    }

    // Add primary segment data
    addSegmentDataToBucket(segments)

    const segmentBars = segments.reduce<LineDescriptor[]>((memo, s) => {
      // Only add lines when we also have data. Charts will do weird aninmations otherwise.
      if (s.data == null) return memo
      memo.push({
        color: getColor(theme, `${s.color}.500`),
        label: s.label,
        getValue: (d) => {
          return d[s.label]?.valuePct ?? 0
        },
      })
      return memo
    }, [])

    const data = Object.values(buckets)
    return {
      data,
      segmentBars,
    }
  }, [segments, score.maxScore, theme])
}
