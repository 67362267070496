import { themes as chartThemes } from '@capturi/charts'
import {
  Box,
  Flex,
  RangeSlider,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  RangeSliderTrack,
  useToken,
} from '@chakra-ui/react'
import { i18n } from '@lingui/core'
import clamp from 'lodash/clamp'
import React from 'react'
import { useMeasure } from 'react-use'
import { BarChart, ResponsiveContainer, XAxis, YAxis } from 'recharts'

export type ScoreBucket = {
  score: number
  // biome-ignore lint/suspicious/noExplicitAny: legacy
  [key: string]: any
}

const chartMargin = {
  top: 8,
  right: 0,
  left: 0,
  bottom: 0,
}

const DistributionChartContext = React.createContext<{
  liveRange: number[]
}>({
  liveRange: [0, 10],
})

export type ScoreDistributionChartProps = {
  data: ScoreBucket[]
  range?: number[]
  onRangeChange: (range: number[]) => void
  onScoreBarClick?: (score: number) => void
  showYAxis?: boolean
}

export function ScoreDistributionChart({
  data,
  range,
  onRangeChange,
  onScoreBarClick,
  showYAxis = false,
  children,
}: React.PropsWithChildren<ScoreDistributionChartProps>): React.ReactElement {
  const scores = data.map((x) => x.score)
  const dataMin = scores.length > 0 ? Math.min(...scores) : 0
  const dataMax = scores.length > 0 ? Math.max(...scores) : 10

  // live updated range while dragging range slider - used to live update distribution chart
  const [liveRange, setLiveRange] = React.useState(() => {
    return [range?.[0] ?? dataMin, range?.[1] ?? dataMax]
  })

  React.useEffect(() => {
    setLiveRange([range?.[0] ?? dataMin, range?.[1] ?? dataMax])
  }, [range, dataMin, dataMax])

  const [ref, { width }] = useMeasure<HTMLDivElement>()

  /*
    Calculate the position of the range slider so that
    each step is aligned with the center of each bar in 
    the chart
  */
  const yAxisWidth = showYAxis ? 50 : 0
  const rangeSliderOffset = (() => {
    if (width <= 0 || data.length === 0) {
      return undefined
    }
    const contentWidth = width - yAxisWidth
    const bandSize = contentWidth / data.length
    return {
      left: yAxisWidth + bandSize / 2,
      width: contentWidth - bandSize,
    }
  })()

  const ctx: React.ContextType<typeof DistributionChartContext> = {
    liveRange,
  }

  const [cursorBackground] = useToken('colors', ['blackAlpha.200'])

  return (
    <DistributionChartContext.Provider value={ctx}>
      <Flex h="100%" direction="column">
        <Box ref={ref} flex={1}>
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              width={500}
              height={300}
              data={data}
              margin={chartMargin}
              onClick={(nextState) => {
                if (
                  nextState?.activePayload &&
                  nextState.activePayload.length > 0 &&
                  nextState.activePayload[0].payload &&
                  typeof nextState.activePayload[0].payload.score === 'number'
                ) {
                  onScoreBarClick?.(nextState.activePayload[0].payload.score)
                }
              }}
            >
              <defs>
                <style>
                  {`
                    .recharts-tooltip-cursor {
                      pointer-events: auto;
                      cursor: pointer;
                      fill: ${cursorBackground};
                      opacity: 0.5;
                    }
                  `}
                </style>
              </defs>
              <XAxis dataKey="score" axisLine={false} tickLine={false} />
              {showYAxis && (
                <YAxis
                  width={yAxisWidth}
                  stroke={chartThemes.defaultTheme.gridColor}
                  tick={{ fill: chartThemes.defaultTheme.gridTextColor }}
                  tickFormatter={(value) => {
                    if (!Number.isFinite(value)) return ''
                    return i18n.number(value, {
                      style: 'percent',
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 1,
                    })
                  }}
                />
              )}
              {children}
            </BarChart>
          </ResponsiveContainer>
        </Box>
        <Box h="21px" flex="0 0 auto">
          {rangeSliderOffset != null && (
            <Box
              ml={`${rangeSliderOffset.left}px`}
              w={`${rangeSliderOffset.width}px`}
              isolation="isolate"
            >
              <RangeSlider
                value={liveRange}
                onChange={setLiveRange}
                onChangeEnd={onRangeChange}
                min={dataMin}
                max={dataMax}
                step={1}
                colorScheme="primary"
              >
                <RangeSliderTrack bg="gray.200">
                  <RangeSliderFilledTrack />
                </RangeSliderTrack>
                <RangeSliderThumb index={0} />
                <RangeSliderThumb index={1} />
              </RangeSlider>
            </Box>
          )}
        </Box>
      </Flex>
    </DistributionChartContext.Provider>
  )
}

export const RangeAwareBarShape: React.FC<{
  fill?: string
  x?: number
  y?: number
  width?: number
  height?: number
  payload?: ScoreBucket
}> = (props) => {
  const { liveRange } = React.useContext(DistributionChartContext)
  const [defaultFill] = useToken('colors', ['gray.300'])
  const { fill, x, y, width, height, payload } = props

  const isWithinRange =
    payload === undefined
      ? false
      : liveRange[0] <= payload.score && payload.score <= liveRange[1]

  let rx = 4
  if (width) {
    const value = Math.floor(width * 0.08)
    rx = clamp(value, 2, 8)
  }
  return (
    <rect
      x={x}
      y={y}
      width={width}
      height={height}
      rx={rx}
      fill={isWithinRange ? fill : defaultFill}
      style={{
        transition: 'fill 150ms ease',
        cursor: 'pointer',
      }}
    />
  )
}
