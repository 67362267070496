import { atom, useAtom } from 'jotai'
import { atomFamily } from 'jotai/utils'
import { useCallback, useEffect, useRef } from 'react'
import { useLatest, useMountedState } from 'react-use'

export const counterState = atomFamily(
  (_param) => atom<number>(0),
  (a, b) => a === b,
)

export function useOnRevalidateDataSource(
  source: string,
  callback: () => void,
): void {
  const [currentCount] = useAtom(counterState(source))
  const countAtMountRef = useRef(currentCount)
  const cbRef = useLatest(callback)
  const isMounted = useMountedState()

  useEffect(() => {
    if (currentCount === countAtMountRef.current) {
      // no calls to trigger revalidation since the consumer component mounted.
      return
    }
    if (typeof cbRef.current === 'function') {
      if (isMounted()) {
        cbRef.current()
      }
    }
  }, [currentCount, cbRef, isMounted])
}

export function useRevalidateDataSource(source: string): () => void {
  const [, setCount] = useAtom(counterState(source))
  return useCallback(() => {
    setCount((count) => count + 1)
  }, [setCount])
}
