import { Score, useScore } from '@capturi/api-scoring'
import React from 'react'

export function withScore<P extends { uid: string }>(
  WrappedComponent: React.ComponentType<P & { score: Score }>,
): React.FunctionComponent<P> {
  function ComponentWithScore(props: P): React.ReactElement | null {
    const { data: score } = useScore(props.uid)
    if (score == null) return null
    return <WrappedComponent {...props} score={score} />
  }
  return ComponentWithScore
}
