import analytics, { EventParams } from '@capturi/analytics'

type EventType =
  | 'score-tab--changed' // changing score in the tabs list.
  | 'new-score--clicked' // clicking on the "New score" button
  | 'edit-score--clicked' // clicking on the "Edit score" button
  | 'toggle-score-configuration-panel' // expanding/collapsing the score configuration panel
  | 'chart-view--changed' // changing between distribution and time-series views
  | 'breakdown-view--changed' // changing between parameters and users view
  | 'distribution-chart--score-range-changed' // changing score range via the range slider
  | 'distribution-chart--bar-clicked' // clicking on a single bar in the chart
  | 'view-conversations-drawer--clicked' // clicking to open the conversations drawer

export const logEvent = (event: EventType, params?: EventParams): void => {
  analytics.event(`score-insights__${event}`, params)
}
