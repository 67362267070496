import { ShowModal, useModal } from '@capturi/use-modal'

import {
  ScoreConversationsDrawer,
  ScoreConversationsDrawerProps,
} from './ConversationsDrawer'
import {
  ParameterConversationsDrawer,
  ParameterConversationsDrawerProps,
} from './ParameterConversationsDrawer'

export function useScoreConversationsDrawer(): ShowModal<ScoreConversationsDrawerProps> {
  const [open] = useModal(ScoreConversationsDrawer)
  return open
}

export function useScoreParameterConversationsDrawer(): ShowModal<ParameterConversationsDrawerProps> {
  const [open] = useModal(ParameterConversationsDrawer)
  return open
}
