import { ScoreSummary } from '@capturi/api-insights'
import { ColorLabel } from '@capturi/charts'
import { Segment } from '@capturi/filters'
import {
  Description,
  Highlight,
  PlaySnippetsButton,
  Spinner,
} from '@capturi/ui-components'
import {
  HStack,
  List,
  ListItem,
  Tag,
  TagLabel,
  TagLeftIcon,
  Text,
} from '@chakra-ui/react'
import { i18n } from '@lingui/core'
import { Trans, t } from '@lingui/macro'
import React from 'react'
import { MdFlag } from 'react-icons/md'

const formatValue = (value: number): string =>
  i18n.number(value, {
    maximumFractionDigits: 1,
    minimumFractionDigits: 0,
  })

const formatTotal = (value: number): string => i18n.number(value)

const formatRange = (
  min: number | undefined,
  max: number | undefined,
): string => {
  if (min != null && max != null) {
    return `${min} - ${max}`
  }
  if (min != null) {
    return `> ${min} `
  }
  if (max != null) {
    return `< ${max}`
  }
  return ''
}

export type ScoreSummaryProps = {
  segments: Segment<ScoreSummary>[]
  onViewConversations: (index: number, segment: Segment<ScoreSummary>) => void
  goal: { min?: number | undefined; max?: number | undefined } | null
}

export const ScoreSummaryList: React.FC<ScoreSummaryProps> = ({
  segments,
  onViewConversations,
  goal,
}) => {
  return (
    <HStack width="100%" justify="flex-end" gap="4">
      {goal && (
        <Tag size="sm">
          <TagLeftIcon boxSize="12px" as={MdFlag} />
          <TagLabel>
            <Trans>Goal</Trans>: {formatRange(goal?.min, goal?.max)}
          </TagLabel>
        </Tag>
      )}

      <List spacing={2}>
        {segments.map((s, i) => (
          <ListItem
            key={s.label}
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            minH={6}
          >
            <ColorLabel color={s.color} mr={2} />
            {s.data == null ? (
              <Spinner size="sm" m={1} />
            ) : (
              <>
                <Text>
                  <Highlight>
                    {formatValue(s.data.averageScore)}/{s.data.maxPossibleScore}
                  </Highlight>{' '}
                  <Description as="span">
                    (
                    <Trans>
                      {formatTotal(s.data.conversationsTotal)} conversations
                    </Trans>
                    )
                  </Description>
                </Text>
                <PlaySnippetsButton
                  ml={2}
                  label={t`Play audio snippets`}
                  onClick={() => {
                    onViewConversations(i, s)
                  }}
                />
              </>
            )}
          </ListItem>
        ))}
      </List>
    </HStack>
  )
}
