import { ScoreSummary } from '@capturi/api-insights'
import { useScores } from '@capturi/api-scoring'
import { useCurrentUser } from '@capturi/core'
import {
  FilterPeriodSelectContainer,
  MultiSegmentContainer,
  SegmentStatesProvider,
  useFilterDefinitions,
} from '@capturi/filters'
import { ErrorBoundary, usePageTitle } from '@capturi/react-utils'
import { ScoreBadge } from '@capturi/scoring'
import {
  Highlight,
  PageHeading,
  RadioButton,
  RadioButtonGroup,
  Spinner,
} from '@capturi/ui-components'
import {
  Box,
  BoxProps,
  Flex,
  HStack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import { useStore } from '@tanstack/react-store'
import { ButtonLink } from 'components/Link'
import {
  withInitialValuesFromRole,
  withSavedFilterUrlSync,
} from 'features/filters/filter-state-enhancers'
import { useAtom } from 'jotai'
import ResolutionButtons from 'pages/analytics/shared/components/ResolutionButtons'
import {
  TimeResolutionProvider,
  useTimeResolutionContext,
} from 'pages/analytics/shared/contexts/ResolutionContext'
import useOnlyWeekdaysToggle from 'pages/analytics/shared/hooks/useOnlyWeekdaysToggle'
import React from 'react'
import { NoScoresPlaceholder } from '../components/NoScoresPlaceholder'
import { ScoreConfigurationPanel } from '../components/ScoreConfigurationPanel'
import { ScoreDistributionView } from '../components/ScoreDistributionView'
import { ScoreTimeSeriesView } from '../components/ScoreTimeSeriesView'
import { logEvent } from '../events'
import { withScore } from '../hocs/withScore'
import { useScoreSummaries } from '../hooks/useScoreSummaries'
import { useTabsState } from '../hooks/useTabsState'
import ScoringIcon from '../icon'
import { routes } from '../routes'
import { selectedScoreAtom } from '../state/selectedScoreState'
import {
  ScoreViewtype,
  selectedScoreViewStore,
} from '../state/selectedScoreView'

function useScrollScoreTabIntoView(
  scoreUid: string,
  deps: Array<unknown> = [],
): void {
  React.useEffect(() => {
    if (!scoreUid) return
    const tabElm = document.querySelector(`[data-score-tab-id='${scoreUid}']`)
    if (tabElm) {
      tabElm.scrollIntoView({
        block: 'end',
        inline: 'center',
        behavior: 'smooth',
      })
    }
  }, [scoreUid, ...deps])
}

const ScoreInsightsContent: React.FC = () => {
  const currentUser = useCurrentUser()
  const { data: scores } = useScores()
  const { summaries, isLoading } = useScoreSummaries({
    suspense: false,
  })

  const [selectedScore, setSelectedScore] = useAtom(selectedScoreAtom)

  const { tabIndex, getTab, tabs } = useTabsState(() => {
    return (scores ?? [])
      .map((x) => ({
        label: x.name,
        value: x.uid,
      }))
      .sort((a, b) => a.label.localeCompare(b.label))
  }, selectedScore)

  useScrollScoreTabIntoView(selectedScore, [isLoading])

  if (scores?.length === 0) {
    return <NoScoresPlaceholder />
  }

  return (
    <Tabs
      index={tabIndex}
      onChange={(index) => {
        const score = getTab(index)
        setSelectedScore(score.value)
        logEvent('score-tab--changed')
      }}
      isLazy
    >
      <Flex>
        <ScrollableTabsContainer>
          <TabList>
            {tabs.map((tab) => {
              const scoreSummary = summaries[tab.value]
              return (
                <Tab
                  key={tab.value}
                  data-score-tab-id={tab.value}
                  whiteSpace="nowrap"
                  alignItems="center"
                >
                  {tab.label}
                  <ScoreTabSummary
                    summary={scoreSummary}
                    isLoading={isLoading}
                  />
                </Tab>
              )
            })}
          </TabList>
        </ScrollableTabsContainer>
        {currentUser.isAdminOrTeamLead && currentUser.permissions.editScore && (
          <Flex
            alignItems="center"
            ml="auto"
            pl={2}
            borderBottom="1px"
            borderColor="gray.200"
          >
            <ButtonLink
              primary
              to={routes.createScore()}
              leftIcon={<ScoringIcon />}
              onClick={() => logEvent('new-score--clicked')}
            >
              <Trans>New score</Trans>
            </ButtonLink>
          </Flex>
        )}
      </Flex>
      <TimeResolutionProvider>
        <TabPanels flex="1">
          {tabs.map((tab) => (
            <TabPanel key={tab.value} px={0} w="full">
              <React.Suspense
                fallback={<Spinner display="block" m="5rem auto" />}
              >
                <ScorePanel uid={tab.value} />
              </React.Suspense>
            </TabPanel>
          ))}
        </TabPanels>
      </TimeResolutionProvider>
    </Tabs>
  )
}

const ScoreTabSummary: React.FC<{
  summary?: ScoreSummary
  isLoading: boolean
}> = ({ summary, isLoading }) => {
  if (isLoading) {
    return <Spinner size="sm" color="gray.500" ml={2} />
  }
  if (summary == null) return null
  return (
    <ScoreBadge
      score={summary.averageScore}
      maxScore={summary.maxPossibleScore}
      ml={2}
      lineHeight={1.25}
    />
  )
}

const ScorePanel = withScore(function ScorePanelWithScore({ uid, score }) {
  const currentUser = useCurrentUser()
  const filterDefinitions = useFilterDefinitions(currentUser)

  const { resolution, setResolution } = useTimeResolutionContext()
  const [plotOnlyWeekdays, OnlyWeekdaysToggle] = useOnlyWeekdaysToggle()

  const scoreViewOption = useStore(selectedScoreViewStore)

  return (
    <>
      <ScoreConfigurationPanel uid={uid} />
      <Box my={4}>
        <Highlight>
          <Trans>Filters</Trans>
        </Highlight>
        <MultiSegmentContainer filterDefinitions={filterDefinitions} />
        <Flex mt={4} wrap="wrap">
          <Box my={1}>
            <RadioButtonGroup
              name="resolution"
              onChange={(nextValue: ScoreViewtype) => {
                selectedScoreViewStore.setState(() => nextValue)
                logEvent('chart-view--changed', { view: nextValue })
              }}
              value={scoreViewOption}
            >
              <RadioButton value="distribution">
                <Trans>Distribution of score</Trans>
              </RadioButton>
              <RadioButton value="time-series">
                <Trans>Development over time</Trans>
              </RadioButton>
            </RadioButtonGroup>
          </Box>
          {scoreViewOption === 'time-series' && (
            <HStack spacing={4} ml="auto" my={1}>
              <ResolutionButtons
                resolution={resolution}
                setResolution={setResolution}
              />
              {OnlyWeekdaysToggle}
            </HStack>
          )}
        </Flex>
        {score && (
          <Box mt={4}>
            {scoreViewOption === 'distribution' && (
              <ScoreDistributionView score={score} />
            )}
            {scoreViewOption === 'time-series' && (
              <ScoreTimeSeriesView
                score={score}
                resolution={resolution}
                onlyWeekDays={plotOnlyWeekdays}
              />
            )}
          </Box>
        )}
      </Box>
    </>
  )
})

const ScrollableTabsContainer: React.FC<BoxProps> = (props) => {
  return (
    <Box
      flex={1}
      width="100%"
      overflowX="auto"
      sx={{
        overflow: 'auto',
        msOverflowStyle: 'none',
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
      }}
      pb="1px"
      {...props}
    />
  )
}

const SegmentStatesProviderWithPresets = withInitialValuesFromRole(
  withSavedFilterUrlSync(SegmentStatesProvider),
)

export default function ScoreInsightsPage(): React.ReactElement {
  usePageTitle(t`Scoring`)

  return (
    <Box>
      <Flex align="start" justify="space-between">
        <PageHeading>{t`Scoring`}</PageHeading>
        <FilterPeriodSelectContainer />
      </Flex>
      <Box mt={8}>
        <ErrorBoundary>
          <SegmentStatesProviderWithPresets>
            <ScoreInsightsContent />
          </SegmentStatesProviderWithPresets>
        </ErrorBoundary>
      </Box>
    </Box>
  )
}
